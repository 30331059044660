import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import "../layouts/base-styles.scss"
import "../layouts/LayoutCommon"
import { baseClass, childClass } from "../layouts/LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { ArticleMetaData, ReviewMetaData } from "@ViewModels";
import { MdxConnection } from "@GraphQLModels";
import ReviewLinkCard from "components/cards/ReviewLinkCard";
import PageMeta from "components/texty/PageMeta";


export default function AllReviews() {
  return (
    <StaticQuery
      query={graphql`
        query AllReviewsQuery {
          allMdx(
          filter: { frontmatter: { templateKey: { eq: "review" } } }
        ) {
            edges {
              node {
                fields {
                  route
                }
                frontmatter {
                  title
                  subTitle
                  date
                  score
                  mainImage
                  templateKey
                }
              }
            }
          }
        }
      `}
      render={data => {
        const allMdx = data.allMdx as MdxConnection;

        return (
          <div className={baseClass}>
            <PageMeta
              title='Reviews'
              route='/all-reviews'>
            </PageMeta>
            <SiteHeader />

            <section className={childClass('container')}>
              <h1 className={childClass('nice-h1')}>Reviews</h1>
              <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} src="https://www.youtube.com/embed?listType=playlist&list=UUGb_dFxx-zoBk2I1ftW55OA" allow="autoplay; encrypted-media"></iframe>
              </div>
            </section>
          </div>
        )
      }}
    />
  )
}
